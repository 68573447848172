@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff !important;
  font-family: 'Poppins', sans-serif !important;
}

Th {
  font-size: 13px !important;
  text-transform: capitalize !important;
  font-family: 'Poppins', sans-serif !important;
  /* width: 200px!important;
  max-width: 200px!important;
  min-width:200px!important; */


}

/* th:first-child{
  width: 50px!important;
  max-width: 50px!important;
  min-width:50px!important;
}
th:nth-child(1),th:nth-child(2),th:nth-child(3),th:nth-child(4),th:nth-child(5),th:nth-child(6),th:nth-child(7),th:nth-child(8),th:nth-child(9),th:nth-child(10),th:nth-child(11),th:nth-child(12),th:nth-child(13),th:nth-child(14),th:nth-child(15),th:nth-child(16),th:nth-child(17),th:nth-child(18),th:nth-child(19),th:nth-child(20),th:nth-child(21),th:nth-child(12),th:nth-child(23),th:nth-child(24),th:nth-child(25){
  width: 200px!important;
  max-width: 200px!important;
  min-width:200px!important;
}
td:nth-child(4),td:nth-child(5),td:nth-child(6),td:nth-child(7),td:nth-child(8),td:nth-child(9),td:nth-child(10),td:nth-child(11),td:nth-child(12),td:nth-child(13),td:nth-child(14),td:nth-child(15),td:nth-child(16),td:nth-child(17),td:nth-child(18),td:nth-child(19),td:nth-child(20),td:nth-child(21),td:nth-child(22),td:nth-child(23),td:nth-child(24),td:nth-child(25){
  width: 200px;
  max-width: 200px;
  min-width:200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
th:last-child(){
 width: auto!important;
 max-width: auto!important;
}
td:last-child(){
  width: auto!important;
  max-width: auto!important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
} */

.remarkTextarea>div {
  margin-bottom: 0px;
}

.remarkTextarea textarea {
  resize: vertical !important;
  height: auto !important;
  min-height: 230px;
}

.left-content {
  background-image: url("../public/images/loginBanner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;

}

.navbarBox.show {
  width: 230px;
  transition: all ease .5s;
  transform: translate(-100%, 0px);
}

.navbarBox {
  width: 230px;
  transition: all ease .5s;
  transform: translate(0%, 0px);
  overflow: auto;
}

.sidebar.hide {
  transform: translate(-230px, 0px);
  transition: all ease .5s;
}

.sidebar {
  transform: translate(0px, 0px);
  transition: all ease .5s;
  position: fixed;
  left: 0;
  height: calc(100vh - 70px);
  top: 70px;
}

.wrapper.full-wrapper {
  width: calc(100% - 0px);
  transform: translate3d(0px, 0px, 0px);
  transition: all ease .5s;
  margin-left: 0px;
}

.wrapper {
  width: calc(100% - 230px);
  margin-left: 230px;
  transform: translate3d(0px, 0px, 0px);
  transition: all ease .5s;
  overflow-y: hidden;
}

.bigLogo {
  display: block;
}

.smallLogo {
  display: none;
}

.uploadImagesBox input {
  opacity: 0;
  width: 150px;
  height: 150px;
  z-index: 2;
  position: relative;
  top: 0px;
  cursor: crosshair;
}

.closeUploadedImage {
  position: absolute;
  top: -15px;
  left: 132px;
  background: #f0ac00 !important;
  color: #fff;
  border-radius: 30px !important;
  cursor: pointer;
}

.uploadImagesBox .uploadedImage {
  position: absolute;
  top: 0px;
  left: 0;
  z-index: -1;
  width: 150px;
  height: 150px;
  border-radius: 10px;
}

.uploadImagesBox .uploadedImageView {
  position: relative;
  top: 0px;
  left: 0;
  z-index: -1;
  width: 150px;
  height: 150px;
  border-radius: 10px;
}


@media (min-width:320px) and (max-width:767px) {
  .filterText {
    display: none;
  }

  .btnText {
    display: none;
  }

  .bigLogo {
    display: none !important;
  }

  .smallLogo {
    display: block;
  }
}

@media (min-width:320px) and (max-width:1199px) {
  .wrapper {
    width: calc(100% - 0px);
    margin-left: 0px;
    transform: translate3d(0px, 0px, 0px);
    transition: all ease .5s;
  }

  .sidebar {
    transform: translate(0px, 0px);
    transition: all ease .5s;
    position: fixed;
    left: 0;
    z-index: 999;
    height: calc(100vh - 70px);
    top: 71px;
    background-color: #fff;
  }
}

/* .error {
  font-size: 12px;
  color: red;
  border-radius: 2px;
  position: relative;
  top: "70%";
  padding: 0px 10px;
} */
.chakra-menu__group {
  overflow-x: hidden;
  height: 150px !important;

}

.topbarSelectBox>div {
  margin-bottom: 0px !important;
}

.uploadDataBox {
  position: relative;
}

.uploadDataBox input {
  opacity: 0;
}

.uploadText {
  position: absolute;
  top: 10px;
  font-size: 12px;
  background: #f0ac001f;
  width: 100%;
  padding: 8px;
  text-align: center;
  border-radius: 5px;
  color: #bb8600;
  z-index: -1;
  border: 1px dashed #f0ac00;
  cursor: pointer;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.pendingDocumentHeader div {
  position: relative;
  padding: 10px;
}

.pendingDocumentHeader div+div:before {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  background: #f0ac00;
  left: -40px;
  top: 0;
}

input[type="text"]:disabled,
input[type="text"]:disabled+label,
input[type="date"]:disabled+label,
input[type="tel"]:disabled,
input[type="date"]:disabled {
  color: #2D3748;
  opacity: 3;
}
