.pagination-container {
    display: flex;
    list-style-type: none;
    justify-content: flex-end;
    margin: 10px 0px;
  // background-color: #fff;
    .pagination-item {
      height: 32px;
      text-align: center;
      margin: auto 4px;
      color: rgba(0, 0, 0, 0.87);
      display: flex;
      box-sizing: border-box;
      align-items: center;
      border-radius:50px!important;
      line-height: 1.43;
      font-size: 13px;
      min-width: 32px;
      margin-top: 0px;
      justify-content: center;
      &.dots:hover {
        background-color: transparent;
        cursor: default;
      }
      &:hover {
        background-color: rgba(209, 20, 20, 0.04);
        cursor: pointer;
      }
  
      &.selected {
      //   background-color: rgba(0, 0, 0, 0.2);
      // background-color:#1AAA00;
      // color: #fff;
      border-radius: 0px;
    
      }
  
      .arrow {
        &::before {
          position: relative;
          /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
          content: '';
          /* By using an em scale, the arrows will size with the font */
          display: inline-block;
          width: 0.4em;
          height: 0.4em;
          border-right: 0.12em solid rgba(0, 0, 0, 0.87);
          border-top: 0.12em solid rgba(0, 0, 0, 0.87);
        }
  
        &.left {
          transform: rotate(-135deg) translate(-50%);
        }
  
        &.right {
          transform: rotate(45deg);
        }
      }
  
      &.disabled {
        pointer-events: none;
  
        .arrow::before {
          border-right: 0.12em solid rgba(0, 0, 0, 0.43);
          border-top: 0.12em solid rgba(0, 0, 0, 0.43);
        }
  
        &:hover {
          background-color: transparent;
          cursor: default;
        }
      }
    }
  }
  